import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MillieApi      from 'app/apis/millie';
import Icon           from 'app/components/common/icon';
import StandardInput  from 'app/components/common/standard-input';
import StandardSelect from 'app/components/common/standard-select';
import countries      from 'app/helpers/countries';

const selectableCountries = countries.sorted.filter(c => c.cc);
const countryOpts = selectableCountries.map((country) => {
  return {label: `${country.flag} ${country.name} (+${country.cc})`, selectedLabel: `${country.flag} +${country.cc}`, value: country.code};
});

class PhoneInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      countryCode: null,
      inputStr: null,
      validateNumber: null,
      validateFailed: false,
      validateSuccess: false,
    };

    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onChangeInputStr = this.onChangeInputStr.bind(this);
    this.onBlurInputStr = this.onBlurInputStr.bind(this);
    this.debouncedValidate = _.debounce(this.validate, 2000);
  }

  componentDidMount() {
    this.setDefaultCountry();
  }

  componentDidUpdate(prevProps) {
    const {phone} = this.props;
    const phoneUpdated = !!(phone?.e164 && (phone.e164 !== prevProps.phone?.e164));
    if (phoneUpdated) {
      this.debouncedValidate.cancel();
      this.setState({inputStr: phone.formatted, countryCode: phone.countryIso2, validateNumber: null, validateFailed: false, validateSuccess: true});
    }
  }

  get inputE164() {
    const {countryCode, inputStr} = this.state;
    const callingCode = countries.byCode[countryCode]?.cc;
    if (!callingCode) return null;
    const numericInput = (inputStr || '').replace(/[^0-9]/g, '');
    if (!numericInput) return null;
    return `+${callingCode}${numericInput}`;
  }

  get validationMsg() {
    const {name, validations} = this.props;
    const pMsg = (validations || {})[name]?.[0];
    if (pMsg) return pMsg;
    if (this.state.validateFailed) return 'must be a valid phone number';
    return null;
  }

  setDefaultCountry() {
    if (this.state.countryCode) return;
    const countryClass = [...document.body.classList].find(cn => cn.startsWith('country-')) || '';
    let countryCode = (countryClass.split('-')[1] || 'us').toUpperCase();
    const validCode = !!selectableCountries.find(c => c.code === countryCode);
    if (!validCode) countryCode = 'US';
    this.setState({countryCode});
  }

  validate() {
    const validateNumber = this.inputE164;
    if (!validateNumber) return;
    if (this.state.validateNumber === validateNumber) return;
    this.setState({validateNumber, validateFailed: false, validateSuccess: false});
    MillieApi.phoneNumbersValidation(validateNumber).then(({phone, e164Input, twilioErrorCodes}) => {
      if (e164Input !== this.state.validateNumber) return;
      if (phone) {
        this.setState({validateNumber: null, validateFailed: false, validateSuccess: true, inputStr: phone.formatted});
        this.props.onChange(phone);
      } else {
        this.setState({validateNumber: null, validateFailed: true,  validateSuccess: false});
      }
    });
  }

  onSelectCountry(countryCode) {
    this.debouncedValidate.cancel();
    this.props.onChange(null);
    this.setState({countryCode}, () => {
      this.validate();
    });
  }

  onChangeInputStr(event) {
    const inputStr = event.target.value;
    this.setState({inputStr});
    this.props.onChange(null);
    this.debouncedValidate();
  }

  onBlurInputStr(event) {
    this.debouncedValidate.flush();
  }

  render() {
    const {
      countryCode, inputStr,
      validateNumber, validateFailed, validateSuccess,
    } = this.state;
    const [validateClass, validateIcon] = (() => {
      if (validateFailed)  return ['validate-failed',  <Icon.AlertCircle className="phone-input-vld-icon failed" />];
      if (validateSuccess) return ['validate-success', <Icon.CheckCircle className="phone-input-vld-icon success" />];
      if (validateNumber)  return ['validate-pending', <Icon.Loading     className="phone-input-vld-icon pending" />];
      return ['', null];
    })();
    const vmsgClass = this.validationMsg ? 'vmsg' : '';

    return (<>
      <div className={`phone-input ${validateClass} ${vmsgClass}`}>
        <div className="phone-input-con">
          <StandardSelect className="phone-input-country" options={countryOpts} value={countryCode} onSelect={this.onSelectCountry} searchLabel="Search" label="Country" />
          <StandardInput className="phone-input-number" name="number" label="1231231234" value={inputStr || ''} onChange={this.onChangeInputStr} onBlur={this.onBlurInputStr} />
          {validateIcon}
        </div>
        {this.validationMsg && (
          <div className="phone-input-vmsg">{this.validationMsg}</div>
        )}
      </div>
    </>);
  }

}

PhoneInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  phone: PropTypes.shape({
    formatted: PropTypes.string.isRequired,
    e164: PropTypes.string.isRequired,
    countryIso2: PropTypes.string.isRequired,
  }),
  validations: PropTypes.object,
  name: PropTypes.string,
};

PhoneInput.defaultProps = {
  className: '',
  name: 'phone',
};

export default PhoneInput;


// - [ ] show validation when validate
// - [ ] show validations
// - [ ] handle phone prop
