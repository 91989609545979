import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import GroupsAx           from 'app/actions/company-admin/groups';
import PageAx             from 'app/actions/company-admin/page-groups';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import ModalCreateSuccess from 'app/components/company-admin/groups/modal-create-success';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalMsTeamsGroup  from 'app/components/company-admin/ms-teams/modal-channel-group';
import ModalSlackGroup    from 'app/components/company-admin/slack/modal-channel-group';
import TopLineStats       from 'app/components/company-admin/top-line-stats';
import PageLoading        from 'app/components/layout/page-loading';
import { ReviewPromptAx } from 'app/ducks/review-prompt';
import format             from 'app/helpers/format';
import utils              from 'app/helpers/utils';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-groups';

class PageCadminGroups extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onCloseCreateSuccessModal = this.onCloseCreateSuccessModal.bind(this);
    this.onCloseSlackModal = this.onCloseSlackModal.bind(this);
    this.onCloseMsTeamsModal = this.onCloseMsTeamsModal.bind(this);
    this.onClickSlack = this.onClickSlack.bind(this);
    this.onClickMsTeams = this.onClickMsTeams.bind(this);
  }

  onCloseCreateSuccessModal() {
    this.props.closeCreateSuccessModal();
    this.props.checkReviewPrompt();
  }

  onCloseSlackModal() {
    this.props.viewSlack(null);
  }

  onCloseMsTeamsModal() {
    this.props.viewMsTeams(null);
  }

  onClickSlack(group, event) {
    event && event.preventDefault();
    this.onCloseCreateSuccessModal();
    this.props.viewSlack(group.id);
  }

  onClickMsTeams(group, event) {
    event && event.preventDefault();
    this.onCloseCreateSuccessModal();
    this.props.viewMsTeams(group.id);
  }

  async onClickDelete(group, event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this group?\n${group.name}\nThis action can not be undone.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (!didConfirm) return;
    const trEl = event.target && event.target.closest('tr');
    const emEl = event.target && event.target.closest('.ellipsis-menu');
    if (trEl) trEl.style.opacity = 0.2;
    if (emEl) emEl.remove();
    const { company, deleteGroup } = this.props;
    deleteGroup(company.id, group.id);
  }

  renderSummary() {
    const { summary } = this.props;
    const stats = [
      {icon: null, key: 'Employees in Groups', valType: 'int',     val: summary?.membersCount},
      {icon: null, key: 'Group Leads',         valType: 'int',     val: summary?.adminsCount},
      {icon: null, key: 'Group Events',        valType: 'int',     val: summary?.eventsCount},
      {icon: null, key: 'Participation',       valType: 'percent', val: summary?.participation},
    ];
    return <TopLineStats stats={stats} color="groups" />;
  }

  renderGroups() {
    const { company, groups } = this.props;
    // if (!campaigns) return null;

    return (
      <table className="ca-box-table ca-groups-table">
        <thead>
          <tr>
            <th></th>
            <th>Group Name</th>
            <th></th>
            <th className="right">Members</th>
            <th>Lead</th>
            <th className="right">Events</th>
            <th>Section</th>
          </tr>
        </thead>
        <tbody>
          {(groups || []).map((group) => {
            const admin = group.adminEmployee;
            const adminOthers = Math.max(group.adminCount - 1, 0);
            return (
              <tr key={group.id}>
                <td className="ca-groups-table-logo">
                  <img src={utils.s3Url(group.logoImgPath)} />
                </td>
                <td>
                  <Link className="pink-hover" href={paths.group(group.id)}>{group.name}</Link>
                </td>
                <td>
                  <EllipsisMenu>
                    <Link href={paths.group(group.id)}><Icon.BrowserPageText />View</Link>
                    <Link href={paths.cadminGroupsEdit(company.slug, group.id)}><Icon.Pencil />Edit</Link>
                    <Link href={paths.cadminGroupEmployees(company.slug, {groupId: group.id})}><Icon.UserMultipleNeutral2 /> Members</Link>
                    <Link href={paths.cadminVolEvents(company.slug, {groupId: group.id})}><Icon.CalendarDate /> Events</Link>
                    <Link onClick={this.onClickDelete.bind(this, group)}><Icon.Bin />Delete</Link>
                    {company.isSlackSetup && (
                      <Link onClick={this.onClickSlack.bind(this, group)}><Icon.LogoSlack />Slack Settings</Link>
                    )}
                    {company.isMsTeamsSetup && (
                      <Link onClick={this.onClickMsTeams.bind(this, group)}><Icon.LogoMsTeams />Teams Settings</Link>
                    )}
                  </EllipsisMenu>
                </td>
                <td className="right">{group.employeeCount}</td>
                <td>
                  {admin ? `${admin.firstName} ${admin.lastName}` : ''}
                  {adminOthers ? ` +${adminOthers}` : ''}
                </td>
                <td className="right">{group.volEventCount}</td>
                <td>{group.groupType?.name}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { company, groups, slackGroup, msTeamsGroup, createdGroup } = this.props;
    if (!company) return <PageLoading />;

    return (
      <CadminLayout className="ca-groups" company={company} activeItem="groups">
        <Meta title="Groups | Millie" />
        {this.renderSummary()}
        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Groups</h1>
            <div className="ca-box-header-controls">
              <Link href={paths.cadminGroupsSettings(company.slug)} className="btn secondary slate">Settings</Link>
              <Link href={paths.cadminGroupsNew(company.slug)} className="btn special groups">Create Group</Link>
            </div>
          </div>
          <div className="ca-box-body">
            {this.renderGroups()}
          </div>
        </div>
        {createdGroup && <ModalCreateSuccess group={createdGroup} onClose={this.onCloseCreateSuccessModal} onClickSlack={company.isSlackSetup ? this.onClickSlack : null} />}
        {slackGroup && <ModalSlackGroup group={slackGroup} onClose={this.onCloseSlackModal} />}
        {msTeamsGroup && <ModalMsTeamsGroup group={msTeamsGroup} onClose={this.onCloseMsTeamsModal} />}
      </CadminLayout>
    );
  }
}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  summary: PageSlx.summary(state),
  groups: PageSlx.groups(state),
  slackGroup: PageSlx.slackGroup(state),
  msTeamsGroup: PageSlx.msTeamsGroup(state),
  createdGroup: PageSlx.createdGroup(state),
});

const dispatchToProps = (dispatch) => ({
  viewSlack: (groupId) => dispatch(PageAx.viewSlack(groupId)),
  viewMsTeams: (groupId) => dispatch(PageAx.viewMsTeams(groupId)),
  closeCreateSuccessModal: () => dispatch(GroupsAx.setCreatedId(null)),
  deleteGroup: (companyId, groupId) => dispatch(GroupsAx.delete(companyId, groupId)),
  checkReviewPrompt: () => dispatch(ReviewPromptAx.checkShow()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminGroups);
