import { combineReducers } from 'redux';

import { reducer as budgets }                from 'app/ducks/company-admin/budgets';
import { reducer as cattrs }                 from 'app/ducks/company-admin/cattrs';
import { reducer as cards }                  from 'app/ducks/company-admin/cards';
import { reducer as companyNteeRules }       from 'app/ducks/company-admin/company-ntee-rules';
import { reducer as employees }              from 'app/ducks/company-admin/employees';
import { reducer as gifts }                  from 'app/ducks/company-admin/gifts';
import { reducer as groups }                 from 'app/ducks/company-admin/groups';
import { reducer as modalDriveTrack }        from 'app/ducks/company-admin/modal-drive-track';
import { reducer as modalLaunch }            from 'app/ducks/company-admin/modal-launch';
import { reducer as modalMsTeamsChannel }    from 'app/ducks/company-admin/modal-ms-teams-channel';
import { reducer as modalVteEdit }           from 'app/ducks/company-admin/modal-vte-edit';
import { reducer as modalVteNew }            from 'app/ducks/company-admin/modal-vte-new';
import { reducer as multiEmpSelect }         from 'app/ducks/company-admin/multi-emp-select';
import { reducer as nonprofits }             from 'app/ducks/company-admin/nonprofits';
import { reducer as pageAnalytics }          from 'app/ducks/company-admin/page-analytics';
import { reducer as pageBudgets }            from 'app/ducks/company-admin/page-budgets';
import { reducer as pageCampaignsTemplates } from 'app/ducks/company-admin/page-campaigns-templates';
import { reducer as pageCattrs }             from 'app/ducks/company-admin/page-cattrs';
import { reducer as pageDriveDonations }     from 'app/ducks/company-admin/page-drive-donations';
import { reducer as pageEmployeesUpload }    from 'app/ducks/company-admin/page-employees-upload';
import { reducer as pageGrantsNew }          from 'app/ducks/company-admin/page-grants-new';
import { reducer as pageGroupEmployees }     from 'app/ducks/company-admin/page-group-employees';
import { reducer as pageGroupsSettings }     from 'app/ducks/company-admin/page-groups-settings';
import { reducer as pageJunkDrawer }         from 'app/ducks/company-admin/page-junk-drawer';
import { reducer as pageNonprofits }         from 'app/ducks/company-admin/page-nonprofits';
import { reducer as pageNteeCodes }          from 'app/ducks/company-admin/page-ntee-codes';
import { reducer as pageGiftsNew }           from 'app/ducks/company-admin/page-gifts-new';
import { reducer as volEvents }              from 'app/ducks/company-admin/vol-events';
import builderBracket       from 'app/reducers/company-admin/builder-bracket';
import builderCampaign      from 'app/reducers/company-admin/builder-campaign';
import builderDashboard     from 'app/reducers/company-admin/builder-dashboard';
import builderGroup         from 'app/reducers/company-admin/builder-group';
import builderVolEvent      from 'app/reducers/company-admin/builder-vol-event';
import companies            from 'app/reducers/company-admin/companies';
import grants               from 'app/reducers/company-admin/grants';
import layout               from 'app/reducers/company-admin/layout';
import modalCreditCard      from 'app/reducers/company-admin/modal-credit-card';
import modalSlackChannel    from 'app/reducers/company-admin/modal-slack-channel';
import offlineDonations     from 'app/reducers/company-admin/offline-donations';
import pageBrackets         from 'app/reducers/company-admin/page-brackets';
import pageBracketsDb       from 'app/reducers/company-admin/page-brackets-dashboard';
import pageBracketsEdit     from 'app/reducers/company-admin/page-brackets-edit';
import pageCampaigns        from 'app/reducers/company-admin/page-campaigns';
import pageCampaignsEdit    from 'app/reducers/company-admin/page-campaigns-edit';
import pageDashboard        from 'app/reducers/company-admin/page-dashboard';
import pageDashboardEdit    from 'app/reducers/company-admin/page-dashboard-edit';
import pageDashboardView    from 'app/reducers/company-admin/page-dashboard-view';
import pageEmployees        from 'app/reducers/company-admin/page-employees';
import pageGifts            from 'app/reducers/company-admin/page-gifts';
import pageGrants           from 'app/reducers/company-admin/page-grants';
import pageGroups           from 'app/reducers/company-admin/page-groups';
import pageGroupsEdit       from 'app/reducers/company-admin/page-groups-edit';
import pageMatchInquiries   from 'app/reducers/company-admin/page-match-inquiries';
import pageMatches          from 'app/reducers/company-admin/page-matches';
import pageOfflineDonations from 'app/reducers/company-admin/page-offline-donations';
import pagePayroll          from 'app/reducers/company-admin/page-payroll';
import pagePurchase         from 'app/reducers/company-admin/page-purchase';
import pageSettings         from 'app/reducers/company-admin/page-settings';
import pageSubscription     from 'app/reducers/company-admin/page-subscription';
import pageTransactions2    from 'app/reducers/company-admin/page-transactions2';
import pageVolAnalytics     from 'app/reducers/company-admin/page-vol-analytics';
import pageVolDfd           from 'app/reducers/company-admin/page-vol-dfd';
import pageVolEvents        from 'app/reducers/company-admin/page-vol-events';
import pageVolEventsEdit    from 'app/reducers/company-admin/page-vol-events-edit';
import pageVolTracking      from 'app/reducers/company-admin/page-vol-tracking';
import pageWallet           from 'app/reducers/company-admin/page-wallet';
import volTimeEntries       from 'app/reducers/company-admin/vol-time-entries';
import wallet               from 'app/reducers/company-admin/wallet';

const rootReducer = combineReducers({
  budgets,
  builderBracket,
  builderCampaign,
  builderDashboard,
  builderGroup,
  builderVolEvent,
  cattrs,
  cards,
  companies,
  gifts,
  grants,
  groups,
  layout,
  modalCreditCard,
  companyNteeRules,
  employees,
  modalDriveTrack,
  modalLaunch,
  modalMsTeamsChannel,
  modalSlackChannel,
  modalVteEdit,
  modalVteNew,
  multiEmpSelect,
  nonprofits,
  offlineDonations,
  pageAnalytics,
  pageBrackets,
  pageBracketsDb,
  pageBracketsEdit,
  pageBudgets,
  pageCampaigns,
  pageCampaignsEdit,
  pageCampaignsTemplates,
  pageCattrs,
  pageDashboard,
  pageDashboardEdit,
  pageDashboardView,
  pageDriveDonations,
  pageEmployeesUpload,
  pageEmployees,
  pageGifts,
  pageGiftsNew,
  pageGrants,
  pageGroups,
  pageGroupsEdit,
  pageGrantsNew,
  pageGroupEmployees,
  pageGroupsSettings,
  pageJunkDrawer,
  pageNonprofits,
  pageNteeCodes,
  pageMatches,
  pageMatchInquiries,
  pageOfflineDonations,
  pagePayroll,
  pagePurchase,
  pageSettings,
  pageSubscription,
  pageTransactions2,
  pageVolEvents,
  pageVolEventsEdit,
  pageVolTracking,
  pageVolAnalytics,
  pageVolDfd,
  pageWallet,
  volEvents,
  volTimeEntries,
  wallet,
});

export default rootReducer;
