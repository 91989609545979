import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CompaniesAx from 'app/actions/company-admin/companies';
import ToastAx     from 'app/actions/toast';
import Checkbox    from 'app/components/common/checkbox';
import Modal       from 'app/components/common/modal';
import CadminSlx   from 'app/selectors/company-admin/';

class ModalFees extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      feesCoverMatch: null,
      feesCoverDonation: null,
      isSaving: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onChangeMatch = this.onChangeMatch.bind(this);
    this.onChangeDonation = this.onChangeDonation.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
  }

  get feesCoverMatch() {
    if (_.isBoolean(this.state.feesCoverMatch)) return this.state.feesCoverMatch;
    return this.props.company.feesCoverMatch;
  }

  get feesCoverDonation() {
    if (_.isBoolean(this.state.feesCoverDonation)) return this.state.feesCoverDonation;
    return this.props.company.feesCoverDonation;
  }

  get attrs() {
    return _.pick(this, ['feesCoverMatch', 'feesCoverDonation']);
  }

  onClose() {
    this.props.onClose();
  }

  onChangeMatch(event) {
    const feesCoverMatch = event.target.checked;
    this.setState({feesCoverMatch});
  }

  onChangeDonation(event) {
    const feesCoverDonation = event.target.checked;
    this.setState({feesCoverDonation});
  }

  onClickSave() {
    const {save, successToast} = this.props;
    this.setState({isSaving: true});
    save(this.attrs).then(() => {
      this.onClose();
      successToast('Fees settings updated.');
    });
  }

  render() {
    const {isSaving} = this.state;
    const btnLabel = isSaving ? 'Saving...' : 'Save Settings';
    const btnDisabled = isSaving;

    return (
      <Modal onClose={this.onClose} className="bform ca-modal-fees">
        <h1 className="bform-h1">Fee Settings</h1>
        <p>A small amount of each donation is used to cover the payment processor and donor advised fund fees. Use these settings if you would like to cover the fees so that nonprofits receive 100% of the donated amount.</p>
        <p>Fee coverages are billed on the 1st of each month in a separate transaction from matches.</p>
        <p>Fee coverages are subject to the company's overall budget, but are not included in individual employee caps.</p>

        <h2 className="bform-h2">Match Fees</h2>
        <div className="ca-modal-fees-cb-row">
          <Checkbox id="cb-fees-match" checked={this.feesCoverMatch} onChange={this.onChangeMatch} isToggle offOk />
          <label htmlFor="cb-fees-match">Cover the fees on donation matches</label>
        </div>

        <h2 className="bform-h2">Employee Donation Fees</h2>
        <div className="ca-modal-fees-cb-row">
          <Checkbox id="cb-fees-donation" checked={this.feesCoverDonation} onChange={this.onChangeDonation} isToggle offOk />
          <label htmlFor="cb-fees-donation">Cover the fees on employees' donations</label>
        </div>

        <div className="bform-actions">
          <div>&nbsp;</div>
          <button className="btn blue" onClick={this.onClickSave} disabled={btnDisabled}>{btnLabel}</button>
        </div>
        
      </Modal>
    );
  }

}

ModalFees.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  company: CadminSlx.company(state),
});
const dispatchToProps = (dispatch) => ({
  save: (attrs) => dispatch(CompaniesAx.update(undefined, attrs)),
  successToast: (msg) => dispatch(ToastAx.success(msg)),
});

export default connect(stateToProps, dispatchToProps)(ModalFees);
