import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Checkbox                from 'app/components/common/checkbox';
import CurrencyInput           from 'app/components/common/currency-input';
import Dropdown                from 'app/components/common/dropdown';
import Icon                    from 'app/components/common/icon';
import Link                    from 'app/components/common/link';
import Modal                   from 'app/components/common/modal';
import Popper                  from 'app/components/common/popper';
import StandardInput           from 'app/components/common/standard-input';
import StandardSelect          from 'app/components/common/standard-select';
import StripeGuarantee         from 'app/components/common/stripe-guarantee';
import ModalCreditCard         from 'app/components/credit-cards/modal-new';
import {
  NONPROFIT_DEFAULT_IMG_URL,
  CompanyNonprofitApprovalStatuses as ApprovalStatuses,
  DonationFreqTypes as FreqTypes,
  UserBalanceTypes as BalanceTypes,
  DonatableTypes,
}                              from 'app/constants';
import CcDuck                  from 'app/ducks/credit-cards';
import FxDuck                  from 'app/ducks/fx-rates';
import Duck                    from 'app/ducks/modal-confirm-donation';
import currencies              from 'app/helpers/currencies';
import fees                    from 'app/helpers/fees';
import format                  from 'app/helpers/format';
import history                 from 'app/history';
import paths                   from 'app/paths';
import prompts                 from 'app/prompts';
import AuthSlx                 from 'app/selectors/auth';

const {Ax, Slx} = Duck;
const UNDO_DUR = 5000;

const ccBrandLabels = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  'american express': 'Amex',
};

const initialState = {
  isEditingAmount: false,
  newCcModalOpen: false,
  showUndo: false,
};

const feeMsg = 'A small amount of the donation is used to cover the payment processor and donor advised fund fees. Check this box if you would like to pay a little extra to cover the fees.';
// const feeCurrencyMsg = 'For non-USD currencies, the fee calculation is approximate due to varying exchange rates.';

class ModalConfirmDonation2 extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refPmDd = React.createRef();
    this.refAmountInput = React.createRef();
    this.undoTimeout = null;

    this.state = {
      ...initialState,
    };

    this.onClickDonate       = this.onClickDonate.bind(this);
    this.onClickUndo         = this.onClickUndo.bind(this);
    this.onCloseModal        = this.onCloseModal.bind(this);
    this.onSelectCampaign    = this.onSelectCampaign.bind(this);
    this.onChangeIsAnonymous = this.onChangeIsAnonymous.bind(this);
    this.onChangeIncludeNote = this.onChangeIncludeNote.bind(this);
    this.onChangeNote        = this.onChangeNote.bind(this);
    this.onChangeBalanceType = this.onChangeBalanceType.bind(this);
    this.onChangeAmount      = this.onChangeAmount.bind(this);
    this.onChangeCoverFee    = this.onChangeCoverFee.bind(this);
    this.onClickEditAmount   = this.onClickEditAmount.bind(this);
    this.onClickSetAmount    = this.onClickSetAmount.bind(this);
    this.onClickNewCc        = this.onClickNewCc.bind(this);
    this.onCloseNewCcModal   = this.onCloseNewCcModal.bind(this);
    this.onClickContinue     = this.onClickContinue.bind(this);
  }

  componentDidUpdate(prevProps) {
    const showDidChange = prevProps.show !== this.props.show;
    if (showDidChange) {
      this.setState({...initialState});
    }
    Popper.updateAll();
  }

  get isPayroll() {
    return this.props.balanceType === BalanceTypes.PAYROLL;
  }

  get isRecurring() {
    return this.props.freqType === FreqTypes.RECURRING;
  }

  get isFund() {
    return this.props.donatableType === DonatableTypes.FUND;
  }

  get employerHasPayroll() {
    return !!_.get(this.props.currentUser, 'employment.company.features.payroll');
  }

  get employerHasMatch() {
    return !!(_.get(this.props.currentUser, 'employment.company.currentMatchBudget.employeeAmount') && _.get(this.props.currentUser, 'employment.company.currentMatchPercent'));
  }

  get employer() {
    return _.get(this.props.currentUser, 'employment.company');
  }

  get hasEnoughBalance() {
    const {currentUser, amount, balanceType} = this.props;
    if (!currentUser) return true;
    if (!amount) return true;
    if (this.isRecurring) return true;
    if (balanceType === BalanceTypes.GIFT) {
      return currentUser.giftBalanceAmount >= amount;
    }
    if (balanceType === BalanceTypes.MAIN) {
      return currentUser.balanceAmount >= amount;
    }
    return true;
  }

  get usd() {
    return currencies.byCode.USD;
  }

  get currency() {
    return currencies.byCode[this.props.currencyCode];
  }

  get isBelowMin() {
    const {amount} = this.props;
    if (!amount) return true;
    return amount < this.currency.smallRoundAmount;
  }

  get hasSelectedPm() {
    const {currentUser, ccId, balanceType} = this.props;
    if ([BalanceTypes.MAIN, BalanceTypes.GIFT, BalanceTypes.PAYROLL].includes(balanceType)) {
      return true;
    }
    if (balanceType === BalanceTypes.CC) {
      return !!ccId;
    }
    return false;
  }

  get isTargetUsd() {
    return this.props.targetCurrencyCode === 'USD';
  }

  get showCurrency() {
    return !this.isTargetUsd;
  }

  get usdAmount() {
    const {amount, currencyCode, toUsd} = this.props;
    if (currencyCode === 'USD') return amount;
    return toUsd(currencyCode, amount);
  }

  get isSubmitting() {
    if (this.state.showUndo) return true;
    if (this.props.createPending) return true;
    return false;
  }

  onCloseModal({goToPath} = {}) {
    this.props.dismiss({goToPath});
  }

  onClickContinue() {
    history.millieRefresh();
    this.props.dismiss();
  }

  onSelectCampaign(campaignId) {
    this.props.setCampaignId(campaignId);
  }

  onChangeIsAnonymous(event) {
    const isAnonymous = !event.target.checked;
    this.props.setIsAnonymous(isAnonymous);
  }

  onChangeCoverFee(event) {
    const wantsCoverFee = event.target.checked;
    this.props.setWantsCoverFee(wantsCoverFee);
  }

  onChangeIncludeNote(event) {
    this.props.setNote('');
  }

  onChangeNote(event) {
    const note = event.target.value || '';
    this.props.setNote(note);
  }

  onChangeBalanceType(balanceType) {
    this.props.setBalanceType(balanceType);
  }

  onChangeAmount({amount, currencyCode}) {
    this.props.setAmount({amount, currencyCode});
  }

  async onClickPmBalance(balanceType) {
    const {amount, currencyCode, targetAmount, targetCurrencyCode, toUsd, setAmount, setBalanceType} = this.props;
    // const requiresUsd = pm.type !== BalanceTypes.CC;
    if (currencyCode !== 'USD') {
      const didConfirm = await prompts.confirm({
        msg: 'This payment method requires currency USD. Would you like to switch?',
        cancelBtnLabel: `Stay in ${currencyCode}`,
        confirmBtnLabel: 'Switch to USD',
      });
      if (didConfirm) {
        const usdAmount = toUsd(currencyCode, amount);
        setAmount({amount: usdAmount, currencyCode: 'USD'});
        setBalanceType(balanceType);
      }
      return;
    }
    setBalanceType(balanceType);
    this.refPmDd.current.close({skipCallback: true});
  }

  async onClickPmCc(cc) {
    const {amount, currencyCode, targetAmount, targetCurrencyCode} = this.props;
    this.props.setCreditCard(cc.id);
    this.refPmDd.current.close({skipCallback: true});
    if (targetCurrencyCode !== currencyCode) {
      this.props.setAmount({amount: targetAmount, currencyCode: targetCurrencyCode});
    }
  }

  onClickEditAmount(event) {
    event?.preventDefault && event.preventDefault();
    this.setState({isEditingAmount: true}, () => {
      setTimeout(() => {
        this.refAmountInput.current.inputElement.focus();
      }, 50);
    });
  }
  onClickSetAmount(event) {
    event?.preventDefault && event.preventDefault();
    this.setState({isEditingAmount: false});
  }

  onClickNewCc() {
    this.setState({newCcModalOpen: true});
  }
  onCloseNewCcModal(creditCard) {
    if (creditCard) {
      this.props.setCreditCard(creditCard.id);
    }
    this.setState({newCcModalOpen: false});
  }

  onClickDonate() {
    this.setState({showUndo: true});
    this.undoTimeout = setTimeout(() => {
      this.setState({showUndo: false});
      this.props.create();
    }, UNDO_DUR);
  }

  onClickUndo() {
    clearTimeout(this.undoTimeout);
    this.setState({showUndo: false});
  }

  renderSectionDestination() {
    const {donatable, donatableType} = this.props;
    const {name, city, state, imgUrl} = donatable;
    const isNonprofit = donatableType === DonatableTypes.NONPROFIT;
    const isDefaultImg = !imgUrl;
    const line2 = isNonprofit
      ? (city && state)
        ? [city, state].join(', ')
        : donatable.ein
      : 'multiple nonprofits';

    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Donate To</div>
        <div className="mod-don-sec-content">
          <div className="mod-don-dest">
            <div className={`mod-don-dest-img-con ${isDefaultImg ? 'default' : ''}`}>
              <img className="mod-don-dest-img" src={imgUrl || NONPROFIT_DEFAULT_IMG_URL} />
            </div>
            <div className="mod-don-dest-text">
              <div className="mod-don-dest-text-line1">{name}</div>
              <div className="mod-don-dest-text-line2">{line2}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSectionAmount() {
    const {isEditingAmount} = this.state;
    const {nominalAmount, currencyCode, targetCurrencyCode, currentUser, fromUsdFmt} = this.props;
    const targetCurrency = currencies.byCode[targetCurrencyCode];

    const content = isEditingAmount
      ? (<>
        <div className="mod-don-amount-edit">
          <CurrencyInput className="mod-don-amount-edit-input" amount={nominalAmount} currencyCode={currencyCode} onChange={this.onChangeAmount} onBlur={this.onClickSetAmount} ref={this.refAmountInput} />
          <button className="btn secondary blue mod-don-amount-edit-set" onClick={this.onClickSetAmount}>Update</button>
        </div>
      </>)
      : (<>
        <div className="mod-don-amount-amount">
          <div className="mod-don-amount-amount-val" onClick={this.onClickEditAmount}>
            {`${this.currency.format(nominalAmount)} ${this.showCurrency ? this.currency.code : ''}`}
          </div>
          <a key="2" className="mod-don-amount-amount-edit" href="#" onClick={this.onClickEditAmount}><Icon.Pencil /></a>
        </div>
      </>);

    const showEquiv = targetCurrencyCode !== currencyCode && (currencyCode === 'USD');

    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Amount</div>
        <div className="mod-don-sec-content">
          {content}
          {showEquiv && (
            <p className="mod-don-amount-equiv">{`Approximately ~${fromUsdFmt(targetCurrencyCode, nominalAmount)} ${targetCurrencyCode}`}</p>
          )}
          {this.isBelowMin && (
            <p className="mod-don-validation">Amount must be at least {this.currency.format(this.currency.smallRoundAmount)}.</p>
          )}
        </div>
      </div>
    );
  }

  renderSectionPaymentMethod() {
    const {currentUser, balanceType, creditCards, ccId} = this.props;
    const showGift = !this.isRecurring;
    const showPayroll = this.employerHasPayroll && this.isRecurring;
    let selectedOpt = 'Please select...';
    const opts = [
      <div className="mod-don-pmdd-heading" key="heading1">Credit Cards</div>
    ];

    creditCards.forEach((cc) => {
      const brand = ccBrandLabels[cc.brand] || cc.brand;
      const opt = (
        <div className="mod-don-pmdd-opt" onClick={this.onClickPmCc.bind(this, cc)} key={cc.id}>
          <div className="mod-don-pmdd-opt-line1">
            <div className="mod-don-pmdd-opt-line1-left">{cc.label || 'Credit Card'}</div>
            <div className="mod-don-pmdd-opt-line1-right"><Icon.CreditCard brand={cc.brand} /> {`${brand} **${cc.last4}`}</div>
          </div>
        </div>
      );
      opts.push(opt);
      if (cc.id === ccId) selectedOpt = opt;
    });

    const optNewCc = (
      <div className="mod-don-pmdd-opt" onClick={this.onClickNewCc} key="new-cc">
        <div className="mod-don-pmdd-opt-line1">
          <div className="mod-don-pmdd-opt-line1-left">+ New Credit Card</div>
        </div>
      </div>
    );
    opts.push(optNewCc);

    opts.push(<div className="mod-don-pmdd-heading" key="heading2">Balances</div>);
    const optWallet = (
      <div className="mod-don-pmdd-opt" onClick={this.onClickPmBalance.bind(this, BalanceTypes.MAIN)} key={BalanceTypes.MAIN}>
        <div className="mod-don-pmdd-opt-line1">
          <div className="mod-don-pmdd-opt-line1-left">Wallet Balance</div>
          <div className="mod-don-pmdd-opt-line1-right">{this.usd.format(currentUser.balanceAmount)}</div>
        </div>
      </div>
    );
    opts.push(optWallet);
    if (balanceType === BalanceTypes.MAIN) selectedOpt = optWallet;

    if (showGift) {
      const optGift = (
        <div className="mod-don-pmdd-opt" onClick={this.onClickPmBalance.bind(this, BalanceTypes.GIFT)} key={BalanceTypes.GIFT}>
          <div className="mod-don-pmdd-opt-line1">
            <div className="mod-don-pmdd-opt-line1-left">Gift Balance</div>
            <div className="mod-don-pmdd-opt-line1-right">{this.usd.format(currentUser.giftBalanceAmount)}</div>
          </div>
          {this.employerHasMatch && (<div className="mod-don-pmdd-opt-line2">Not eligible for employer match</div>)}
        </div>
      );
      opts.push(optGift);
      if (balanceType === BalanceTypes.GIFT) selectedOpt = optGift;
    }

    if (showPayroll) {
      opts.push(<div className="mod-don-pmdd-heading" key="heading3">Other Options</div>);
      const optPayroll = (
        <div className="mod-don-pmdd-opt" onClick={this.onClickPmBalance.bind(this, BalanceTypes.PAYROLL)} key={BalanceTypes.PAYROLL}>
          <div className="mod-don-pmdd-opt-line1">
            <div className="mod-don-pmdd-opt-line1-left">Payroll Deduction</div>
          </div>
        </div>
      );
      opts.push(optPayroll);
      if (balanceType === BalanceTypes.PAYROLL) selectedOpt = optPayroll;
    }

    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Payment Method</div>
        <div className="mod-don-sec-content">
          <Dropdown
            ref={this.refPmDd}
            className="mod-don-pmdd"
            button={selectedOpt}
            menu={opts}
            disabled={this.isSubmitting}
          />
          {!this.hasEnoughBalance && (
            <p className="mod-don-validation">Donation amount is greater than balance.</p>
          )}
        </div>
      </div>
    );
  }

  renderSectionFrequency() {
    const {balanceType, targetCurrencyCode} = this.props;
    const exp = (() => {
      if (!this.isRecurring) return null;
      const billDate = numeral(Math.min(moment().date(), 28)).format('0o');
      const dateMsg = `today and on the ${billDate} of each following month`;
      if (balanceType === BalanceTypes.PAYROLL) {
        return `Your donation will process on the 5th of each month via payroll.`;
      }
      if (balanceType === BalanceTypes.MAIN) {
        let txt = `Monthly recurring donations will be deducted from your wallet balance ${dateMsg}. If your wallet balance is too low, your donation will be charged to your default credit card.`;
        if (targetCurrencyCode !== 'USD') {
          txt += ' The charge will be in USD.';
        }
        return txt;
      }
      if (balanceType === BalanceTypes.CC) {
        return `Monthly recurring donations will be charged to your selected credit card ${dateMsg}.`;
      }
      return null;
    })();
    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Frequency</div>
        <div className="mod-don-sec-content">
          <div className="mod-don-freq-heading">
            {this.isRecurring ? <><Icon.SyncHeart /> Monthly Recurring</> : 'One-Time, Now'}
          </div>
          {exp && (<>
            <p className="mod-don-p mod-don-freq-exp">{exp}</p>
            <p className="mod-don-p mod-don-freq-exp">You can cancel any time from the Wallet page.</p>
          </>)}
        </div>
      </div>
    );
  }

  renderSectionMatch() {
    const {matchEstimatePending: pending, matchEstimate: estimate, donatable, balanceType, company} = this.props;
    if (!this.employerHasMatch) {
      if (pending) return null;
      if (!estimate?.budget?.hasAllocation) return null;
    }
    const content = (() => {
      if (pending || !estimate) return null;
      if (estimate.approvalStatus === ApprovalStatuses.BANNED) {
        return {status: 'Not Matched', color: 'red', msg: `Donations to ${donatable.name} will not be matched by your employer.`};
      }
      if (!estimate.budget.hasAllocation || estimate.matchPercent === 0) {
        return {status: 'Not Matched', color: 'gray', msg: `Donations to ${donatable.name} will not be matched by your employer.`};
      }
      if (balanceType === BalanceTypes.GIFT) {
        return {status: 'Ineligible', color: 'gray', msg: `Donations from gift balance are not eligible for match.`};
      }
      if (estimate.empAmounts.left === 0 || estimate.budget.leftAmount === 0) {
        return {status: 'Limit-Reached', color: 'gray', msg: `You and ${this.employer?.name || 'your employer'} have been very generous! You’ve reached your match limit. You can still donate to ${donatable.name}, but it will not be matched by your employer.`};
      }
      if (estimate.approvalStatus === ApprovalStatuses.PENDING) {
        return {status: 'Unknown', color: 'gray', msg: `${donatable.name} is not on the automatic match list for your employer. Your employer will be notified of this donation and can accept or restrict the company’s matched donation.`};
      }
      if (estimate.approvalStatus === ApprovalStatuses.APPROVED) {
        let msg = `${this.employer?.name || 'your employer'} is matching your donation ${format.matchRatio(estimate.matchPercent)}.`;
        if (this.usdAmount) {
          const targetMatchAmount = Math.round((this.usdAmount * estimate.matchPercent) / 100);
          const matchAmount = Math.min(targetMatchAmount, estimate.empAmounts.left, estimate.budget.leftAmount);
          msg += this.showCurrency
            ? ` Estimated match amount is ${this.usd.format(matchAmount)} USD. Final amount is subject to conversion rates and employee and company limits, and it is calculated when the match is processed.`
            : ` Estimated match amount is ${this.usd.format(matchAmount)}. Final amount is subject to employee and company limits, and it is calculated when the match is processed.`;
        }
        return {status: 'Eligible', color: 'green', msg};
      }
      return null;
    })();

    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Match</div>
        <div className="mod-don-sec-content">
          {content ? (
            <div className="mod-don-match">
              <div className={`mod-don-match-pill color-${content.color}`}>{content.status}</div>
              <p className="mod-don-p mod-don-match-msg">{content.msg}</p>
            </div>
          ) : (
            <Icon.Loading className="mod-don-match-loading" />
          )}
        </div>
      </div>
    );
  }

  renderSectionCampaign() {
    const {campaigns, selectedCampaignId} = this.props;
    if (!campaigns || !campaigns.length) return null;
    const content = (() => {
      if (campaigns.length === 1) {
        const campaign = campaigns[0];
        return <Link href={paths.campaign(campaign.id)} target="_blank" className="pink-hover">{campaign.name}</Link>
      } else {
        const options = campaigns.map((campaign) => {
          return {label: campaign.name, value: campaign.id};
        });
        return <StandardSelect options={options} onSelect={this.onSelectCampaign} value={selectedCampaignId} disabled={this.isSubmitting} />;
      }
    })();
    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Campaign</div>
        <div className="mod-don-sec-content mod-don-camps">{content}</div>
      </div>
    );
  }

  renderSectionOptions() {
    const {
      currentUser, donatable, isAnonymous, note, balanceType,
      wantsCoverFee, showCoverFee, feeAmount, coverFee, amount,
    } = this.props;
    if (this.isFund) return null;
    const showNotesField = note != null;

    return (
      <div className="mod-don-sec">
        <div className="mod-don-sec-name">Options</div>
        <div className="mod-don-sec-content">
          <div className="mod-don-opts-anon mod-don-opts-cb-con">
            <Checkbox id={`anonymous-${donatable.id}`} onChange={this.onChangeIsAnonymous} checked={!isAnonymous} disabled={this.isSubmitting} />
            <label htmlFor={`anonymous-${donatable.id}`}>Share my info with {donatable.name}</label>
          </div>
          <div className="mod-don-opts-note">
            {showNotesField ? (
              <StandardInput type="textarea" label="Note to nonprofit" name="note" value={note || ''} onChange={this.onChangeNote} disabled={this.isSubmitting} />
            ) : (
              <div className="mod-don-opts-cb-con">
                <Checkbox id={`include-note-${donatable.id}`} onChange={this.onChangeIncludeNote} checked={false} disabled={this.isSubmitting} />
                <label htmlFor={`include-note-${donatable.id}`}>Add a note</label>
              </div>
            )}
          </div>
          {showCoverFee && (
            <div className="mod-don-opts-fee">
              <div className="mod-don-opts-cb-con">
                <Checkbox id={`cover-fee-cb`} onChange={this.onChangeCoverFee} checked={wantsCoverFee} disabled={this.isSubmitting} />
                <label htmlFor={`cover-fee-cb`}>
                  Cover Fees (+ {this.currency.format(feeAmount)})
                </label>
                <Popper tagType="button" popContent={feeMsg} hoverTrigger blurUntrigger focusTrigger className="mod-don-opts-fee-info">
                  <Icon.InfoCircle />
                </Popper>
              </div>
              {coverFee && (
                <div className="mod-don-opts-fee-total"><strong>{`Total: ${this.currency.format(amount)}`}</strong></div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderActions() {
    const {isEditingAmount, showUndo} = this.state;
    const {amount, balanceType, matchEstimatePending, donatable} = this.props;
    const disabled = (() => {
      // if (donatable.countryCode && donatable.countryCode !== 'US') return true;
      if (this.isSubmitting) return true;
      if (this.isBelowMin) return true;
      if (!this.hasEnoughBalance) return true;
      if (!this.hasSelectedPm) return true;
      if (matchEstimatePending) return true;
      if (isEditingAmount) return true;
      return false;
    })();
    const text = (() => {
      if (showUndo) return 'Waiting...';
      const amountFmt = this.currency.format(amount);
      if (this.isRecurring) {
        return this.isSubmitting ? 'Starting Recurring Donation...' : 'Start Recurring Donation Now';
      }
      if (this.isSubmitting) return 'Donating...';
      const isCc = balanceType === BalanceTypes.CC;
      if (isCc) return `Pay & Donate ${amountFmt} ${this.showCurrency ? this.currency.code : ''} Now`;
      return `Donate ${amountFmt} ${this.showCurrency ? this.currency.code : ''} Now`;
    })();
    return (
      <div className="mod-don-actions">
        <button key="btn-confirm" onClick={this.onClickDonate} disabled={disabled} className="btn special green main">{text}</button>
        {showUndo && (
          <button className="mod-don-actions-undo" onClick={this.onClickUndo}>
            <svg viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="11" style={{animationDuration: `${UNDO_DUR}ms`}} />
            </svg>
            Undo
          </button>
        )}
      </div>
    );
  }

  renderGuarantee() {
    const {balanceType} = this.props;
    const isCc = balanceType === BalanceTypes.CC;
    const shouldShow = !!(isCc || (this.isRecurring && !this.isPayroll));
    if (!shouldShow) return null;
    return (
      <div className="mod-don-guarantee">
        <StripeGuarantee bold />
      </div>
    );
  }

  renderError() {
    const {createSuccess, createError, chargeError, chargeSuccess, chargeErrorStripeMsg: stripeMsg, balanceType} = this.props;
    if (this.isSubmitting || createSuccess || !createError) return null;
    const msg = (() => {
      if (chargeSuccess) return 'Oops! Your credit card was successfully charged and funds were added to your wallet balance, but something went wrong while submitting the donation. Please try again and use Wallet Balance as the Payment Method.';
      if (chargeError) return 'Oops! Something went wrong while charging your credit card.' + (stripeMsg ? ` Message from payment processor: ${stripeMsg}` : '');
      if (this.isPayroll) return 'Oops! Something went wrong while starting your payroll deduction recurring donation. Please try again.';
      if (this.isRecurring) return 'Oops! Something went wrong while starting your recurring donation. Please check if your credit card has been charged before trying again.';
      return 'Oops! Something went wrong while submitting the donation. Please check if funds have been deducted from your balance before trying again.';
    })();
    return (
      <p className="mod-don-create-error">{msg}</p>
    );
  }

  renderSuccess() {
    const {donatable, amount, currencyCode, balanceType, creditCards, ccId} = this.props;
    const msg1 = this.isRecurring
      ? `You just set a recurring donation for ${donatable.name} ✨`
      : `You just backed ${donatable.name} ✨`;
    let amountFmt = this.currency.format(amount);
    if (this.showCurrency) amountFmt += ` ${currencyCode}`;
    const isCc = balanceType === BalanceTypes.CC;
    const msg2 = (() => {
      if (this.isPayroll) return `Your donation will process on the 5th of each month via payroll. You can change this any time from the Wallet page.`;
      if (this.isRecurring) {
        const billDate = numeral(Math.min(moment().date(), 28)).format('0o');
        let str = isCc ? `${amountFmt} was charged to your credit card.` : `${amountFmt} was deducted from your Wallet Balance.`;
        str += ` Future donations will process on the ${billDate} of each month. You can change this any time from the Wallet page.`;
        return str;
      }
      if (isCc) {
        const cc = creditCards.find(cc => cc.id === ccId);
        const ccLabel = cc ? `${ccBrandLabels[cc.brand] || cc.brand} **${cc.last4}` : 'credit card';
        return `${amountFmt} was charged to your ${ccLabel}.`;
      }
      return `${amountFmt} was deducted from your ${balanceType === BalanceTypes.MAIN ? 'Wallet Balance' : 'Gift Balance'}.`;
    })();

    return (
      <div className="mod-don-success">
        <div className="mod-don-success-msg1">{msg1}</div>
        <div className="mod-don-success-msg2">{msg2}</div>
        <div className="mod-don-success-actions">
          <button className="btn blue" onClick={this.onClickContinue}>Continue</button>
        </div>
      </div>
    );
  }

  render() {
    const {show, createSuccess, currentUser} = this.props;
    const {newCcModalOpen} = this.state;
    if (!show) return null;
    const title = (() => {
      if (createSuccess) return `Thank You, ${currentUser.firstName}!`;
      return 'Donate';
    })();

    return (
      <Modal className="mod-don" onClose={this.onCloseModal}>
        <div className="mod-don-h1-con">
          <h1 className="mod-don-h1">{title}</h1>
        </div>
        {createSuccess ? (
          this.renderSuccess()
        ) : (<>
          {this.renderSectionDestination()}
          {this.renderSectionAmount()}
          {this.renderSectionPaymentMethod()}
          {this.renderSectionFrequency()}
          {this.renderSectionMatch()}
          {this.renderSectionCampaign()}
          {this.renderSectionOptions()}
          {this.renderActions()}
          {this.renderGuarantee()}
          {this.renderError()}
          {newCcModalOpen && (
            <ModalCreditCard onClose={this.onCloseNewCcModal} />
          )}
        </>)}
      </Modal>
    );
  }
}

const stateToProps = state => ({
  currentUser: AuthSlx.currentUser(state),
  company: AuthSlx.employerCompany(state),
  toUsd: FxDuck.Slx.toUsd(state),
  fromUsdFmt: FxDuck.Slx.fromUsdFmt(state),
  creditCards: CcDuck.Slx.creditCards(state),

  show: Slx.show(state),
  selectedCampaignId: Slx.selectedCampaignId(state),
  isAnonymous: Slx.isAnonymous(state),
  note: Slx.note(state),
  amount: Slx.amount(state),
  currencyCode: Slx.currencyCode(state),
  targetAmount: Slx.targetAmount(state),
  targetCurrencyCode: Slx.targetCurrencyCode(state),
  nominalAmount: Slx.nominalAmount(state),
  coverFee: Slx.coverFee(state),
  wantsCoverFee: Slx.wantsCoverFee(state),
  showCoverFee: Slx.showCoverFee(state),
  feeAmount: Slx.feeAmount(state),
  creditCard: Slx.creditCard(state),
  balanceType: Slx.balanceType(state),
  ccId: Slx.ccId(state),
  freqType: Slx.freqType(state), 
  campaigns: Slx.campaigns(state),
  donatable: Slx.donatable(state),
  donatableType: Slx.donatableType(state),
  matchEstimatePending: Slx.matchEstimatePending(state),
  matchEstimate: Slx.matchEstimate(state),
  createPending: Slx.createPending(state),
  createSuccess: Slx.createSuccess(state),
  createError: Slx.createError(state),
  chargePending: Slx.chargePending(state),
  chargeSuccess: Slx.chargeSuccess(state),
  chargeError: Slx.chargeError(state),
  chargeErrorStripeMsg: Slx.chargeErrorStripeMsg(state),
});

const dispatchToProps = (dispatch) => ({
  create: () => dispatch(Ax.create()),
  dismiss: ({goToPath} = {}) => dispatch(Ax.dismiss({goToPath})),
  setCampaignId: (campaignId) => dispatch(Ax.setCampaignId(campaignId)),
  setBalanceType: (balanceType) => dispatch(Ax.setBalanceType(balanceType)),
  setCreditCard: (ccId) => dispatch(Ax.setCreditCard(ccId)),
  setWantsCoverFee: (wantsCoverFee) => dispatch(Ax.setWantsCoverFee(wantsCoverFee)),
  setIsAnonymous: (isAnonymous) => dispatch(Ax.setIsAnonymous(isAnonymous)),
  setNote: (note) => dispatch(Ax.setNote(note)),
  setAmount: ({currencyCode, amount}) => dispatch(Ax.setAmount({currencyCode, amount})),
  loadFx: () => dispatch(FxDuck.Ax.fetchLatest()),
  loadCcs: () => dispatch(CcDuck.Ax.fetchAll()),
});

export default connect(stateToProps, dispatchToProps)(ModalConfirmDonation2);
