
const flags = {};

const defineFlag = (key, description, isDeprecated=false) => {
  flags[key] = {key, description, isDeprecated};
};

defineFlag('funds', 'Controls visibility of the pooled funds feature for campaigns.');
defineFlag('ms-teams', 'Controls visibility MS Teams features.');
defineFlag('intl', 'Controls visibility and access to international nonprofits.');
defineFlag('every-cent', 'Controls access to the DAF Funds Overview page.');
defineFlag('tracker', 'Controls visibility of the donation tracker.');
defineFlag('vol-filters', 'Controls visibility of new filters on volunteer page.');
defineFlag('cattrs', 'Controls visibility of custom attributes features.');
defineFlag('cover-fee', 'Controls access to fee-covering features.');

const sortedFlags = Object.values(flags).sort((a, b) => {
  if (a.isDeprecated && !b.isDeprecated) return 1;
  if (!a.isDeprecated && b.isDeprecated) return -1;
  if (a.key > b.key) return 1;
  if (a.key < b.key) return -1;
  return 0;
});

export {
  sortedFlags,
};

export default flags;
