import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';

import PageAx             from 'app/actions/company-admin/page-vol-events';
import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import Icon               from 'app/components/common/icon';
import Link               from 'app/components/common/link';
import Meta               from 'app/components/common/meta';
import Pagination         from 'app/components/common/pagination';
import Popper             from 'app/components/common/popper';
import Select             from 'app/components/common/select';
import StandardSelect     from 'app/components/common/standard-select';
import EntityInput        from 'app/components/company-admin/common/entity-input';
import CadminLayout       from 'app/components/company-admin/layout/';
import ModalMsTeamsEvent  from 'app/components/company-admin/ms-teams/modal-channel-vol-event';
import ModalSlackEvent    from 'app/components/company-admin/slack/modal-channel-vol-event';
import ModalCreateSuccess from 'app/components/company-admin/volunteer/modal-create-success';
import ModalEventsEdit    from 'app/components/company-admin/volunteer/modal-events-simple-edit';
import RequireRole        from 'app/components/gating/require-role';
import PageLoading        from 'app/components/layout/page-loading';
import VolEventCard       from 'app/components/volunteer/vol-event-card';
import {
  VolEventStatuses as Statuses,
  VolEventTypes as Types,
}                         from 'app/constants';
import VolEventsDuck      from 'app/ducks/company-admin/vol-events';
import { ReviewPromptAx } from 'app/ducks/review-prompt';
import utils              from 'app/helpers/utils';
import history            from 'app/history';
import Metrics            from 'app/metrics';
import paths              from 'app/paths';
import prompts            from 'app/prompts';
import CadminSlx          from 'app/selectors/company-admin/';
import PageSlx            from 'app/selectors/company-admin/page-vol-events';

const StatusLabels = {
  [null]: 'All Events',
  [Statuses.LIVE]: 'Live',
  [Statuses.ENDED]: 'Ended',
};

const getFormattedDate = (event) => {
  if (event.isOngoing) return 'Ongoing';
  if (!event.startDate) return null;
  // drop timezone info so moment doesn't convert it to local
  const startDateOnly = event.startDate.split('T')[0];
  return moment(startDateOnly).format('l');
};

const TypeLabels = {
  [Types.VOL_OPP]: 'Volunteer',
  [Types.EVENT]: 'Group',
};

const statusOptions = [
  {label: 'Live', value: Statuses.LIVE},
  {label: 'Ended', value: Statuses.ENDED},
];

class PageCadminVolEvents extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      editEvent: null,
    };

    this.onCloseCreateSuccessModal = this.onCloseCreateSuccessModal.bind(this);
    this.onCloseSlackModal = this.onCloseSlackModal.bind(this);
    this.onCloseMsTeamsModal = this.onCloseMsTeamsModal.bind(this);
    this.onClickSlack = this.onClickSlack.bind(this);
    this.onClickMsTeams = this.onClickMsTeams.bind(this);
    this.onSelectStatus = this.onSelectStatus.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.onChangePrimaryGroup = this.onChangePrimaryGroup.bind(this);
    this.onChangeNonprofit = this.onChangeNonprofit.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
  }

  get activeItem() {
    const { type } = this.props.searchParams;
    if (type === Types.VOL_OPP) return 'vol-events';
    if (type === Types.EVENT) return 'events';
    return null;
  }

  get hasGroups() {
    return !!this.props.company?.features?.groups;
  }

  onCloseSlackModal() {
    this.props.viewSlack(null);
  }
  onCloseMsTeamsModal() {
    this.props.viewMsTeams(null);
  }

  onClickSlack(volEvent, event) {
    event && event.preventDefault();
    this.onCloseCreateSuccessModal();
    this.props.viewSlack(volEvent.id);
  }
  onClickMsTeams(volEvent, event) {
    event && event.preventDefault();
    this.onCloseCreateSuccessModal();
    this.props.viewMsTeams(volEvent.id);
  }

  onCloseCreateSuccessModal() {
    this.props.closeCreateSuccessModal();
    this.props.checkReviewPrompt();
  }

  async onClickDelete(volEvent, event) {
    event.preventDefault();
    const msg = `Are you sure you want to delete this event?\n${volEvent.title}\nThis can not be undone.`;
    const didConfirm = await prompts.confirm({msg, danger: 'Delete'});
    if (!didConfirm) return;
    const trEl = event.target && event.target.closest('tr');
    const emEl = event.target && event.target.closest('.ellipsis-menu');
    if (trEl) trEl.style.opacity = 0.2;
    if (emEl) emEl.remove();
    const { company, deleteEvent } = this.props;
    deleteEvent(company.id, volEvent.id);
  }

  async onClickArchive(volEvent, event) {
    event.preventDefault();
    const msg = `Are you sure you want to mark this event as Ended?\n${volEvent.title}\nThis can not be undone.`;
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Mark as Ended'});
    if (!didConfirm) return;
    const { company, archiveEvent } = this.props;
    archiveEvent(company.id, volEvent.id);
  }

  onSelectStatus(status) {
    this.props.setSearchParams({status, page: 1});
  }

  onChangeGroup(group) {
    this.props.setSearchParams({groupId: group?.id, page: 1});
  }

  onChangePrimaryGroup(group) {
    this.props.setSearchParams({primaryGroupId: group?.id, page: 1});
  }

  onChangeNonprofit(nonprofit) {
    this.props.setSearchParams({nonprofitId: nonprofit?.id, page: 1});
  }

  onSelectPage(page) {
    this.props.setSearchParams({page});
  }

  onClickEdit(volEvent, event) {
    event.preventDefault();
    this.setState({editEvent: volEvent});
  }
  onCloseEdit() {
    this.setState({editEvent: null});
  }

  async onClickConvert(volEvent, event) {
    event.preventDefault();
    const msg = 'This will take you to the event builder. Once the builder has been submitted, the event will become visible.';
    const didConfirm = await prompts.confirm({msg, confirmBtnLabel: 'Continue'});
    if (!didConfirm) return;
    const path = paths.cadminEditVolEvent(this.props.company.slug, volEvent.id);
    history.push(path);
  }

  renderNewButton() {
    const {company, searchParams: {type}} = this.props;

    return (
      <>
        {(type !== Types.EVENT) && <Link href={paths.cadminNewVolEvent(company.slug)} className="btn special orange">New Volunteer Event</Link>}
        {(type !== Types.VOL_OPP) && <Link href={paths.cadminNewVolEvent(company.slug, {type: Types.EVENT})} className="btn special groups">New Group Event</Link>}
      </>
    );
  }

  renderVolEvents() {
    const { company, volEvents } = this.props;
    return (
      <table className="ca-box-table events">
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th></th>
            <th></th>
            <th>Nonprofit</th>
            {this.hasGroups && (
              <th className="hide-small">Primary Group</th>
            )}
            <th>Registered</th>
            <th>Location</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {volEvents.map((ve) => {
            const location = ve.isRemote ? 'Remote' : `${ve.city}, ${ve.state}`;
            const showArchive = ve.isOngoing && !ve.isArchived;
            let spots = `${ve.totalParticipants}`;
            if (Number.isFinite(ve.totalCapacity)) {
              spots += ` / ${ve.totalCapacity}`;
            }
            const isVol = ve.type === Types.VOL_OPP;
            const vteLinkText = isVol ? 'Manage Volunteers & Hours' : 'Manage Attendees';
            const analHref = isVol
              ? paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', volEventId: ve.id},   Metrics.presets.volunteering.key)
              : paths.cadminAnalytics(company.slug, {groupBy: 'employeeId', groupEventId: ve.id}, Metrics.presets.groupEvents.key);
            return (
              <tr key={ve.id}>
                <td>
                  {ve.isHidden ? ve.title : (
                    <Link href={paths.volEvent(ve.id)} className="pink-hover">{ve.title}</Link>
                  )}
                </td>
                <td className="edit">
                  <EllipsisMenu>
                    {ve.isHidden ? (<>
                      <Link href="#" onClick={this.onClickEdit.bind(this, ve)}><Icon.BrowserPageText />View/Edit</Link>
                      <Link href="#" onClick={this.onClickConvert.bind(this, ve)}><Icon.Pencil />Convert to Visible Event</Link>
                    </>) : (<>
                      <Link href={paths.volEvent(ve.id)}><Icon.BrowserPageText />Employee View</Link>
                      <Link href={paths.cadminEditVolEvent(company.slug, ve.id)}><Icon.Pencil />Edit</Link>
                    </>)}
                    <Link href={paths.cadminVolTracking(company.slug, {volEventId: ve.id, eventType: ve.type})}><Icon.TaskChecklistCheck /> {vteLinkText}</Link>
                    {company.isSlackSetup && !ve.isHidden && (
                      <Link onClick={this.onClickSlack.bind(this, ve)}><Icon.LogoSlack />Slack Settings</Link>
                    )}
                    {company.isMsTeamsSetup && (
                      <Link onClick={this.onClickMsTeams.bind(this, ve)}><Icon.LogoMsTeams />Teams Settings</Link>
                    )}
                    <RequireRole>
                      <Link href={analHref}><Icon.AnalyticsBars/>Analytics</Link>
                    </RequireRole>
                    {showArchive && (
                      <Link onClick={this.onClickArchive.bind(this, ve)}><Icon.CalendarCheck1 />Mark as Ended</Link>
                    )}
                    <Link onClick={this.onClickDelete.bind(this, ve)}><Icon.Bin />Delete</Link>
                  </EllipsisMenu>
                </td>
                <td className="icon">
                  {ve.isHidden && (
                    <Popper tagType="div" hoverTrigger popContent="Hidden Event - For Tracking Only">
                      <Icon.ViewOff />
                    </Popper>
                  )}
                </td>
                <td>
                  <span className={`ca-vol-events-type-${ve.type}`}>{TypeLabels[ve.type]}</span>
                </td>
                <td>
                  {ve.nonprofit && (
                    <Link href={paths.nonprofit(ve.nonprofit)} className="pink-hover">{ve.nonprofit.name}</Link>
                  )}
                </td>
                {this.hasGroups && (
                  <td className="hide-small">
                    {ve.primaryGroup && (
                      <Link href={paths.group(ve.primaryGroup.id)} className="pink-hover">{ve.primaryGroup.name}</Link>
                    )}
                  </td>
                )}
                <td>{spots}</td>
                <td>{location}</td>
                <td>{getFormattedDate(ve)}</td>
                <td className={`status ${ve.status}`}>{StatusLabels[ve.status]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderFilters() {
    const {searchParams} = this.props;
    const {status, groupId, primaryGroupId, nonprofitId} = searchParams;

    return (
      <div className="ca-main-filters">
        <div className={`ca-main-filters-filter ${(status) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Status</label>
          <StandardSelect
            options={statusOptions}
            value={this.props.searchParams.status || null}
            onSelect={this.onSelectStatus}
            allowClear
            label="All"
          />
        </div>
        {this.hasGroups && (<>
          <div className={`ca-main-filters-filter ${(primaryGroupId) ? 'filled' : ''}`}>
            <label className="ca-main-filters-filter-label">Primary Group</label>
            <EntityInput.Group onChange={this.onChangePrimaryGroup} groupId={primaryGroupId} />
          </div>
          <div className={`ca-main-filters-filter ${(groupId) ? 'filled' : ''}`}>
            <label className="ca-main-filters-filter-label">Group</label>
            <EntityInput.Group onChange={this.onChangeGroup} groupId={groupId} />
          </div>
        </>)}
        <div className={`ca-main-filters-filter ${(nonprofitId) ? 'filled' : ''}`}>
          <label className="ca-main-filters-filter-label">Nonprofit</label>
          <EntityInput.Nonprofit onChange={this.onChangeNonprofit} nonprofitId={nonprofitId} />
        </div>
      </div>
    );
  }

  render() {
    const {editEvent} = this.state;
    const {company, createdEvent, slackVolEvent, msTeamsVolEvent, searchParams, pagination} = this.props;
    const {type} = searchParams;
    if (!company) return <PageLoading />;
    const title = (type === Types.VOL_OPP)
      ? 'Volunteer Events'
      : (type === Types.EVENT)
        ? 'Group Events'
        : 'Events';

    return (
      <CadminLayout className="ca-vol-events" company={company} activeItem={this.activeItem}>
        <Meta title="Events | Millie" />

        <div className="ca-main-head">
          <h1 className="ca-main-head-h1">{title}</h1>
          <div className="ca-main-head-actions">
            {this.renderNewButton()}
          </div>
        </div>

        {this.renderFilters()}

        <div className="ca-box">
          <div className="ca-box-header">
            <h1 className="ca-box-header-title">Events</h1>
            <div className="ca-box-header-controls">
              <a className="btn secondary small icon" href={paths.cadminVolEventsCsv(company.slug, searchParams)}><Icon.CommonFileTextDownload /> CSV</a>
            </div>
          </div>
          {company.features.groups && (
            <div className="ca-box-tabs">
              <div className="ca-box-tabs-tabs">
                <Link className={`ca-box-tabs-tab ${!type ? 'active' : ''}`} href={paths.cadminVolEvents(company.slug, {...searchParams, page: null, type: null})}>All</Link>
                <Link className={`ca-box-tabs-tab ${(type === Types.VOL_OPP) ? 'active' : ''}`} href={paths.cadminVolEvents(company.slug, {...searchParams, page: null, type: Types.VOL_OPP})}>Volunteer</Link>
                <Link className={`ca-box-tabs-tab ${(type === Types.EVENT) ? 'active' : ''}`} href={paths.cadminVolEvents(company.slug, {...searchParams, page: null, type: Types.EVENT})}>Group</Link>
              </div>
            </div>
          )}
          <div className="ca-box-body">
            {this.renderVolEvents()}
            <Pagination pagination={pagination} onSelectPage={this.onSelectPage} />
          </div>
        </div>
        {createdEvent && (
          <ModalCreateSuccess volEvent={createdEvent} onClose={this.onCloseCreateSuccessModal} onClickSlack={company.isSlackSetup ? this.onClickSlack : null} />
        )}
        {editEvent && (
          <ModalEventsEdit volEvent={editEvent} onClose={this.onCloseEdit} />
        )}
        {slackVolEvent && <ModalSlackEvent volEvent={slackVolEvent} onClose={this.onCloseSlackModal} />}
        {msTeamsVolEvent && <ModalMsTeamsEvent volEvent={msTeamsVolEvent} onClose={this.onCloseMsTeamsModal} />}
      </CadminLayout>
    );
  }

}

const stateToProps = (state) => ({
  company: CadminSlx.company(state),

  searchParams: PageSlx.searchParams(state),
  volEvents: PageSlx.volEvents(state),
  pagination: PageSlx.pagination(state),
  createdEvent: PageSlx.createdEvent(state),
  slackVolEvent: PageSlx.slackVolEvent(state),
  msTeamsVolEvent: PageSlx.msTeamsVolEvent(state),
});

const dispatchToProps = (dispatch) => ({
  deleteEvent: (companyId, volEventId) => dispatch(VolEventsDuck.Ax.delete(companyId, volEventId)),
  archiveEvent: (companyId, volEventId) => dispatch(VolEventsDuck.Ax.archive(companyId, volEventId)),
  closeCreateSuccessModal: () => dispatch(VolEventsDuck.Ax.setCreatedId(null)),
  viewSlack: (volEventId) => dispatch(PageAx.viewSlack(volEventId)),
  viewMsTeams: (volEventId) => dispatch(PageAx.viewMsTeams(volEventId)),
  setSearchParams: (newParams) => dispatch(PageAx.setSearchParams(newParams)),
  checkReviewPrompt: () => dispatch(ReviewPromptAx.checkShow()),
});

export default connect(stateToProps, dispatchToProps)(PageCadminVolEvents);
